import React from 'react';
import { Lock, DollarSign, Cpu } from 'lucide-react';
import { Section } from './Section';
import { FeatureCard } from './FeatureCard';
import { Button } from './Button';

const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfg6uxipdiXThh8Vd6G_Da-MIOdOEuWgq6nbD2Tb2oGHZDWpQ/viewform';

export function WhyDifferent() {
  const differentiators = [
    {
      icon: Lock,
      title: "No Equity Required",
      description: "Stay in control of your business without giving up ownership."
    },
    {
      icon: DollarSign,
      title: "Flat-Fee Pricing",
      description: "No hidden costs—just straightforward, affordable pricing."
    },
    {
      icon: Cpu,
      title: "AI-Driven Efficiency",
      description: "Our advanced AI tools accelerate development, so you can launch faster and smarter."
    }
  ];

  return (
    <Section id="why-different" className="bg-gradient-to-br from-emerald-50 to-white">
      <div className="max-w-4xl mx-auto text-center mb-16">
        <h2 className="text-4xl font-bold mb-6">
          A New Approach to MVP Development
        </h2>
        <p className="text-xl text-gray-600">
          At Orchard, we're not just a service; we're your partner in growth. Traditional development agencies can be costly and complex, often leaving founders feeling overwhelmed. Orchard is different. We simplify the process with AI technology, transparent pricing, and a founder-first approach that keeps you in control.
        </p>
      </div>

      <div className="grid md:grid-cols-3 gap-12 mb-16">
        {differentiators.map((diff, index) => (
          <FeatureCard
            key={index}
            icon={diff.icon}
            title={diff.title}
            description={diff.description}
          />
        ))}
      </div>

      <div className="text-center">
        <Button 
          size="lg"
          onClick={() => window.open(GOOGLE_FORM_URL, '_blank')}
        >
          Schedule a Time to Chat
        </Button>
      </div>
    </Section>
  );
}