import React from 'react';
import { Send } from 'lucide-react';
import { Button } from './Button';

const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfg6uxipdiXThh8Vd6G_Da-MIOdOEuWgq6nbD2Tb2oGHZDWpQ/viewform';

export function WaitlistForm() {
  return (
    <Button 
      onClick={() => window.open(GOOGLE_FORM_URL, '_blank')}
      size="lg"
      className="whitespace-nowrap text-xl px-12 py-6 border-2 border-white"
    >
      Join Waitlist
      <Send className="w-6 h-6 ml-3" />
    </Button>
  );
}