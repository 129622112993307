import React from 'react';
import { cn } from '../utils/cn';

interface SectionProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
}

export function Section({ children, className, ...props }: SectionProps) {
  return (
    <section 
      className={cn("py-20 px-6", className)}
      {...props}
    >
      {children}
    </section>
  );
}