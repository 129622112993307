import React from 'react';
import { Section } from './Section';
import { FAQ } from './FAQ';

export function FAQSection() {
  return (
    <Section id="faq" className="bg-white">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-16">
          Got Questions? We've Got Answers.
        </h2>
        
        <FAQ />
      </div>
    </Section>
  );
}