import React from 'react';
import { Lightbulb, Code, Heart } from 'lucide-react';
import { Section } from './Section';
import { PersonaCard } from './PersonaCard';
import { Button } from './Button';

const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfg6uxipdiXThh8Vd6G_Da-MIOdOEuWgq6nbD2Tb2oGHZDWpQ/viewform';

export function WhoWeHelp() {
  const personas = [
    {
      icon: Lightbulb,
      title: "The First-Time Founder",
      description: "Launching your first product? We'll guide you every step of the way."
    },
    {
      icon: Code,
      title: "The Non-Technical Visionary",
      description: "You bring the vision; we bring the tech. No experience needed."
    },
    {
      icon: Heart,
      title: "The Social Impact Entrepreneur",
      description: "Grow your mission-driven product without compromising your values."
    }
  ];

  return (
    <Section id="who-we-help" className="bg-white">
      <div className="max-w-4xl mx-auto text-center mb-16">
        <h2 className="text-4xl font-bold mb-6">
          Built for Founders with Big Ideas and Bold Visions
        </h2>
        <p className="text-xl text-gray-600">
          Orchard is for founders who want to make an impact but need a clear, affordable path to market. If you're ready to bring your idea to life, Orchard is here to make it happen
        </p>
      </div>

      <div className="grid md:grid-cols-3 gap-8 mb-16">
        {personas.map((persona, index) => (
          <PersonaCard
            key={index}
            icon={persona.icon}
            title={persona.title}
            description={persona.description}
          />
        ))}
      </div>

      <div className="text-center">
        <Button 
          size="lg"
          onClick={() => window.open(GOOGLE_FORM_URL, '_blank')}
        >
          See If You're a Fit
        </Button>
      </div>
    </Section>
  );
}