import React from 'react';
import { LucideIcon } from 'lucide-react';

interface PersonaCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
}

export function PersonaCard({ icon: Icon, title, description }: PersonaCardProps) {
  return (
    <div className="bg-white p-8 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl">
      <div className="flex justify-center mb-6">
        <Icon className="w-12 h-12 text-emerald-600" />
      </div>
      <h3 className="text-xl font-semibold mb-4 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
}