import React from 'react';
import { Sprout } from 'lucide-react';
import { Button } from './Button';
import { Link, useLocation } from 'react-router-dom';

const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfg6uxipdiXThh8Vd6G_Da-MIOdOEuWgq6nbD2Tb2oGHZDWpQ/viewform';

export function Navbar() {
  const location = useLocation();

  const scrollToSection = (sectionId: string) => {
    if (location.pathname !== '/') {
      window.location.href = `/${sectionId}`;
      return;
    }

    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="container mx-auto px-6 py-6">
      <div className="flex justify-between items-center">
        <Link to="/" className="flex items-center space-x-2">
          <Sprout className="w-8 h-8 text-emerald-600" />
          <div className="flex flex-col">
            <span className="text-2xl font-bold text-gray-900 leading-none">Orchard</span>
            <span className="text-[0.560rem] -tracking-[0.05em] text-gray-600 font-bold -mt-0.5 ml-[0.9em]">BY FRUIT VENTURES</span>
          </div>
        </Link>
        
        <div className="hidden md:flex items-center space-x-8">
          <button 
            onClick={() => scrollToSection('how-it-works')}
            className="text-gray-600 hover:text-emerald-600 transition-colors duration-200 font-medium"
          >
            How It Works
          </button>
          <button 
            onClick={() => scrollToSection('why-different')}
            className="text-gray-600 hover:text-emerald-600 transition-colors duration-200 font-medium"
          >
            Benefits
          </button>
          <button 
            onClick={() => scrollToSection('testimonials')}
            className="text-gray-600 hover:text-emerald-600 transition-colors duration-200 font-medium"
          >
            Success Stories
          </button>
          <button 
            onClick={() => scrollToSection('faq')}
            className="text-gray-600 hover:text-emerald-600 transition-colors duration-200 font-medium"
          >
            FAQ
          </button>
          <Button 
            onClick={() => window.open(GOOGLE_FORM_URL, '_blank')}
            variant="secondary"
          >
            Contact Us
          </Button>
        </div>
      </div>
    </nav>
  );
}