import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface FAQItemProps {
  question: string;
  answer: string;
}

function FAQItem({ question, answer }: FAQItemProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full py-4 flex justify-between items-center text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium text-gray-900">{question}</span>
        {isOpen ? (
          <ChevronUp className="w-5 h-5 text-emerald-600" />
        ) : (
          <ChevronDown className="w-5 h-5 text-emerald-600" />
        )}
      </button>
      {isOpen && (
        <div className="pb-4 text-gray-600">
          {answer}
        </div>
      )}
    </div>
  );
}

export function FAQ() {
  const faqs = [
    {
      question: "Do I need technical experience to work with Orchard?",
      answer: "Not at all! We specialize in helping non-technical founders bring their ideas to life. Our AI-powered platform and experienced team will guide you through the entire process."
    },
    {
      question: "What's the typical timeline for MVP development?",
      answer: "Most MVPs are completed within 6-8 weeks, depending on complexity. Our AI-driven approach significantly accelerates development while maintaining quality."
    },
    {
      question: "How does your pricing work?",
      answer: "We offer transparent, flat-fee pricing based on your project scope. No equity required, no hidden fees. Schedule a consultation to receive a detailed quote."
    },
    {
      question: "What happens after my MVP is complete?",
      answer: "We provide ongoing support and can help scale your product based on user feedback. Our team can also assist with deployment, maintenance, and future iterations."
    }
  ];

  return (
    <div className="max-w-3xl mx-auto">
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
}