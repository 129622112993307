import React from 'react';
import { MessageSquare, Cpu, Rocket } from 'lucide-react';
import { Section } from './Section';
import { Button } from './Button';

const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfg6uxipdiXThh8Vd6G_Da-MIOdOEuWgq6nbD2Tb2oGHZDWpQ/viewform';

interface StepProps {
  icon: React.ReactNode;
  number: number;
  title: string;
  description: string;
}

function Step({ icon, number, title, description }: StepProps) {
  return (
    <div className="flex flex-col items-center md:items-start md:flex-row gap-6">
      <div className="flex-shrink-0">
        <div className="w-16 h-16 rounded-full bg-emerald-100 flex items-center justify-center relative">
          {icon}
          <span className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-emerald-600 text-white text-sm flex items-center justify-center">
            {number}
          </span>
        </div>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-2 text-center md:text-left">{title}</h3>
        <p className="text-gray-600 text-center md:text-left">{description}</p>
      </div>
    </div>
  );
}

export function HowItWorks() {
  const steps = [
    {
      icon: <MessageSquare className="w-8 h-8 text-emerald-600" />,
      title: "Share Your Vision",
      description: "Tell us about your idea, and we'll work with you to map out an MVP that captures your vision. No technical expertise required—just bring your passion."
    },
    {
      icon: <Cpu className="w-8 h-8 text-emerald-600" />,
      title: "AI-Powered Development",
      description: "Our AI product development methodology, giving you a functional MVP at a fraction of the cost and time of traditional methods."
    },
    {
      icon: <Rocket className="w-8 h-8 text-emerald-600" />,
      title: "Launch and Grow",
      description: "With your MVP in hand, you're ready to gather real feedback, reach your market, and take the next steps with confidence. We'll be there to support you as you grow."
    }
  ];

  return (
    <Section id="how-it-works" className="bg-gray-50">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-16">
          From Idea to MVP in Three Simple Steps
        </h2>
        
        <div className="space-y-16">
          {steps.map((step, index) => (
            <Step
              key={index}
              icon={step.icon}
              number={index + 1}
              title={step.title}
              description={step.description}
            />
          ))}
        </div>

        <div className="text-center mt-16">
          <Button 
            size="lg"
            onClick={() => window.open(GOOGLE_FORM_URL, '_blank')}
          >
            Join Waitlist
          </Button>
        </div>
      </div>
    </Section>
  );
}