import React from 'react';
import { Section } from './Section';

export function PrivacyPolicy() {
  return (
    <Section className="bg-white">
      <div className="max-w-4xl mx-auto prose prose-emerald">
        <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
        
        <p className="text-lg mb-6">Last updated: March 14, 2024</p>

        <p className="mb-8">At Fruit Ventures LLC ("we," "us," or "our"), we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">1. Information We Collect</h2>
        
        <h3 className="text-xl font-semibold mt-6 mb-3">1.1 Personal Information</h3>
        <p>We may collect personal information that you voluntarily provide, including but not limited to:</p>
        <ul>
          <li>Name and contact information</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Company information</li>
          <li>Information about your business idea or project</li>
        </ul>

        <h3 className="text-xl font-semibold mt-6 mb-3">1.2 Automatically Collected Information</h3>
        <p>When you visit our website, we automatically collect certain information, including:</p>
        <ul>
          <li>IP address</li>
          <li>Browser type</li>
          <li>Device information</li>
          <li>Operating system</li>
          <li>Usage patterns and preferences</li>
          <li>Cookies and similar tracking technologies</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">2. How We Use Your Information</h2>
        <p>We use the collected information for various purposes, including:</p>
        <ul>
          <li>Providing and maintaining our services</li>
          <li>Communicating with you about our services</li>
          <li>Processing your requests and transactions</li>
          <li>Sending you marketing and promotional materials</li>
          <li>Improving our website and services</li>
          <li>Analyzing usage patterns and trends</li>
          <li>Protecting against unauthorized access</li>
          <li>Complying with legal obligations</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">3. Information Sharing and Disclosure</h2>
        <p>We may share your information with:</p>
        <ul>
          <li>Service providers and business partners</li>
          <li>Professional advisors and consultants</li>
          <li>Law enforcement or regulatory authorities</li>
          <li>Potential buyers or investors (in connection with a business transaction)</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Data Security</h2>
        <p>We implement appropriate technical and organizational security measures to protect your information, including:</p>
        <ul>
          <li>Encryption of data in transit and at rest</li>
          <li>Regular security assessments</li>
          <li>Access controls and authentication</li>
          <li>Secure data storage and backup</li>
          <li>Employee training on data protection</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">5. Your Rights and Choices</h2>
        <p>You have certain rights regarding your personal information:</p>
        <ul>
          <li>Access your personal information</li>
          <li>Correct inaccurate information</li>
          <li>Request deletion of your information</li>
          <li>Object to processing of your information</li>
          <li>Withdraw consent</li>
          <li>Opt-out of marketing communications</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">6. Children's Privacy</h2>
        <p>Our services are not directed to children under 13. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">7. International Data Transfers</h2>
        <p>Your information may be transferred and processed in countries other than your own. We ensure appropriate safeguards are in place for such transfers.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">8. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">9. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy or our privacy practices, please contact us at:</p>
        <p>Fruit Ventures LLC<br />
        Email: privacy@orchard.dev</p>
      </div>
    </Section>
  );
}