import React from 'react';
import { Section } from './Section';
import { Button } from './Button';

const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfg6uxipdiXThh8Vd6G_Da-MIOdOEuWgq6nbD2Tb2oGHZDWpQ/viewform';

export function CallToAction() {
  return (
    <Section className="bg-emerald-600 text-white">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-6">
          Ready to Grow Your Idea?
        </h2>
        <p className="text-xl mb-12 opacity-90">
          Join our waitlist to be among the first founders to build with Orchard. Get early access, exclusive benefits, and updates on our launch.
        </p>
        <div className="flex justify-center">
          <Button 
            size="lg"
            className="whitespace-nowrap text-xl px-12 py-6 border-2 border-white"
            onClick={() => window.open(GOOGLE_FORM_URL, '_blank')}
          >
            Join Waitlist
          </Button>
        </div>
      </div>
    </Section>
  );
}