import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Hero } from './components/Hero';
import { WhyOrchard } from './components/WhyOrchard';
import { HowItWorks } from './components/HowItWorks';
import { WhoWeHelp } from './components/WhoWeHelp';
import { WhyDifferent } from './components/WhyDifferent';
import { Testimonials } from './components/Testimonials';
import { FAQSection } from './components/FAQSection';
import { CallToAction } from './components/CallToAction';
import { Footer } from './components/Footer';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { TermsOfService } from './components/TermsOfService';
import { CookiePolicy } from './components/CookiePolicy';

function HomePage() {
  return (
    <>
      <Hero />
      <WhyOrchard />
      <HowItWorks />
      <WhoWeHelp />
      <WhyDifferent />
      <Testimonials />
      <FAQSection />
      <CallToAction />
    </>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <div className="min-h-screen bg-white">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/cookies" element={<CookiePolicy />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}