import React from 'react';
import { Quote } from 'lucide-react';

interface TestimonialProps {
  quote: string;
  author: string;
  role: string;
  image: string;
}

export function Testimonial({ quote, author, role, image }: TestimonialProps) {
  return (
    <div className="bg-white p-6 rounded-xl shadow-lg">
      <Quote className="w-8 h-8 text-emerald-600 mb-4" />
      <p className="text-gray-700 mb-4 italic">{quote}</p>
      <div className="flex items-center">
        <img
          src={image}
          alt={author}
          className="w-12 h-12 rounded-full object-cover mr-4"
        />
        <div>
          <h4 className="font-semibold text-gray-900">{author}</h4>
          <p className="text-gray-600 text-sm">{role}</p>
        </div>
      </div>
    </div>
  );
}