import React from 'react';
import { Zap, Users, PiggyBank } from 'lucide-react';
import { Section } from './Section';
import { FeatureCard } from './FeatureCard';

export function WhyOrchard() {
  const features = [
    {
      icon: Zap,
      title: "Fast and Affordable",
      description: "AI-assisted development means lower costs and faster timelines."
    },
    {
      icon: Users,
      title: "Founder-First",
      description: "You stay in control—no equity trade-offs, just partnership and support."
    },
    {
      icon: PiggyBank,
      title: "Transparent Pricing",
      description: "Flat fees with flexible payment options. No surprises."
    }
  ];

  return (
    <Section id="why-orchard" className="bg-white">
      <div className="max-w-4xl mx-auto text-center mb-16">
        <h2 className="text-4xl font-bold mb-6">
          Bringing Your Vision to Life Should Be Simple
        </h2>
        <div className="space-y-6 text-gray-600 text-lg">
          <p>
            Too often, founders face high costs, technical roadblocks, and compromises just to get their ideas off the ground. At Orchard, we believe every entrepreneur deserves a fair shot at success, without needing deep pockets or a technical background.
          </p>
          <p>
            Orchard is for founders who want to make an impact but need a clear, affordable path to market. If you're ready to bring your idea to life, Orchard is here to make it happen.
          </p>
        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-8 mt-12">
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </div>
    </Section>
  );
}