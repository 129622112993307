import React from 'react';
import { Sprout } from 'lucide-react';
import { Section } from './Section';
import { WaitlistForm } from './WaitlistForm';

export function Hero() {
  return (
    <Section className="bg-gradient-to-b from-emerald-50 to-white">
      <div className="max-w-6xl mx-auto text-center">
        <div className="flex justify-center mb-6">
          <Sprout className="w-16 h-16 text-emerald-600" />
        </div>
        <h1 className="text-5xl md:text-6xl font-bold text-gray-900 mb-6">
          Where Ideas Take Root and Grow
        </h1>
        <p className="text-xl md:text-2xl text-gray-600 mb-12 max-w-3xl mx-auto">
          Orchard is for founders who want to make an impact but need a clear, affordable path to market. If you're ready to bring your idea to life, Orchard is here to make it happen.
        </p>
        <WaitlistForm />
      </div>
    </Section>
  );
}