import React from 'react';
import { Section } from './Section';
import { Testimonial } from './Testimonial';
import { Button } from './Button';

const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfg6uxipdiXThh8Vd6G_Da-MIOdOEuWgq6nbD2Tb2oGHZDWpQ/viewform';

export function Testimonials() {
  const testimonials = [
    {
      quote: "Orchard made the MVP process feel easy and achievable. I was able to launch my product quickly, and it cost far less than I expected. Now, I'm getting real user feedback and planning my next steps!",
      author: "Sarah M.",
      role: "Founder, FlowTrack",
      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=200&h=200"
    },
    {
      quote: "As a non-technical founder, I was worried about building my product. Orchard guided me through each step, making sure I stayed in control. I felt supported, empowered, and ready to take my idea to market.",
      author: "Brandon J.",
      role: "Founder, FlexHelp",
      image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=200&h=200"
    }
  ];

  return (
    <Section id="testimonials" className="bg-emerald-50">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-16">
          Real Founders, Real Growth
        </h2>

        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {testimonials.map((testimonial, index) => (
            <Testimonial
              key={index}
              quote={testimonial.quote}
              author={testimonial.author}
              role={testimonial.role}
              image={testimonial.image}
            />
          ))}
        </div>

        <div className="text-center">
          <Button 
            size="lg"
            onClick={() => window.open(GOOGLE_FORM_URL, '_blank')}
          >
            Learn How We Can Help You
          </Button>
        </div>
      </div>
    </Section>
  );
}