import React from 'react';
import { Section } from './Section';

export function TermsOfService() {
  return (
    <Section className="bg-white">
      <div className="max-w-4xl mx-auto prose prose-emerald">
        <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>
        
        <p className="text-lg mb-6">Last updated: March 14, 2024</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">1. Agreement to Terms</h2>
        <p>By accessing our website, you agree to be bound by these Terms of Service and to comply with all applicable laws and regulations.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">2. Use License</h2>
        <p>Permission is granted to temporarily access the materials on Orchard's website for personal, non-commercial transitory viewing only.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">3. Disclaimer</h2>
        <p>The materials on Orchard's website are provided on an 'as is' basis. Orchard makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Limitations</h2>
        <p>In no event shall Orchard or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Orchard's website.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">5. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at:</p>
        <p>Email: legal@orchard.dev</p>
      </div>
    </Section>
  );
}