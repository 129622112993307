import React from 'react';
import { Section } from './Section';

export function CookiePolicy() {
  return (
    <Section className="bg-white">
      <div className="max-w-4xl mx-auto prose prose-emerald">
        <h1 className="text-4xl font-bold mb-8">Cookie Policy</h1>
        
        <p className="text-lg mb-6">Last updated: March 14, 2024</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">1. What Are Cookies</h2>
        <p>Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and more useful to you.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">2. How We Use Cookies</h2>
        <p>We use cookies for the following purposes:</p>
        <ul>
          <li>To enable certain functions of the Service</li>
          <li>To provide analytics</li>
          <li>To store your preferences</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">3. Your Choices Regarding Cookies</h2>
        <p>If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Contact Us</h2>
        <p>If you have any questions about our Cookie Policy, please contact us at:</p>
        <p>Email: privacy@orchard.dev</p>
      </div>
    </Section>
  );
}