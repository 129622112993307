import React from 'react';
import { Sprout, Linkedin } from 'lucide-react';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-300 py-16">
      <div className="container mx-auto px-6">
        <div className="max-w-lg mx-auto">
          <div className="text-center space-y-4">
            <div className="flex items-center justify-center">
              <Sprout className="w-8 h-8 text-emerald-500" />
              <div className="flex flex-col ml-2">
                <span className="text-2xl font-bold text-white leading-none">Orchard</span>
                <span className="text-[0.560rem] -tracking-[0.05em] text-gray-400 font-bold -mt-0.5 ml-[0.9em]">BY FRUIT VENTURES</span>
              </div>
            </div>
            <p className="text-sm">Where Ideas Take Root and Grow</p>
            <div className="flex justify-center">
              <a 
                href="https://www.linkedin.com/company/fruit-ventures"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-emerald-500 transition-colors duration-200"
              >
                <Linkedin className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-12 pt-8 text-sm text-gray-400">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p>&copy; {new Date().getFullYear()} Fruit Ventures LLC. All rights reserved.</p>
            <div className="flex space-x-6">
              <Link to="/privacy" className="hover:text-emerald-500 transition-colors duration-200">
                Privacy Policy
              </Link>
              <Link to="/terms" className="hover:text-emerald-500 transition-colors duration-200">
                Terms of Service
              </Link>
              <Link to="/cookies" className="hover:text-emerald-500 transition-colors duration-200">
                Cookie Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}